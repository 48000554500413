<template>
  <div class="invoice-refund-rules">
    <div class="refund-header">
      {{ $t('sales.invoices.invoice.cancel.labels.timeUntilReserve', {time: timeToReserve}) }}
    </div>

    <div class="refund-rules-list">
      <div class="refund-rules-item">
        <div class="refund-label">
          {{ $t('sales.invoices.invoice.cancel.labels.timeRefund') }}
        </div>
        <div class="refund-content">
          <div v-if="refundTime.percent > 0" class="refund-percent">
            % {{ refundTime.percent }}
          </div>
          <div class="refund-price">
            {{ addComma(refundTime.price || '0') || '0' }}
            {{ $locale.currency() }}
          </div>
        </div>
      </div>
      <div v-if="Object.values(refundCoach).length > 0" class="refund-rules-item">
        <div class="refund-label">
          {{ $t('sales.invoices.invoice.cancel.labels.coachRefund') }}
        </div>
        <div class="refund-content">
          <div class="refund-percent">
            % {{ refundCoach.percent }}
          </div>
          <div class="refund-price">
            {{ addComma(refundCoach.price || '0') || '0' }}
            {{ $locale.currency() }}
          </div>
        </div>
      </div>
      <div v-if="Object.values(refundAddon).length > 0" class="refund-rules-item">
        <div class="refund-label">
          {{ $t('sales.invoices.invoice.cancel.labels.addonRefund') }}
        </div>
        <div class="refund-content">
          <div v-if="refundAddon.percent > 0" class="refund-percent">
            % {{ refundAddon.percent }}
          </div>
          <div class="refund-price">
            {{ addComma(refundAddon.price || '0') || '0' }}
            {{ $locale.currency() }}
          </div>
        </div>
      </div>
      <div class="refund-rules-item">
        <div class="refund-label">
          {{ $t('sales.invoices.invoice.cancel.labels.totalRefundPrice') }}
        </div>
        <div class="refund-content">
          <div class="refund-price text-success">
            {{ addComma(totalRefundPrice || '0') || '0' }}
            {{ $locale.currency() }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {calculateCancelSaleInvoicePrice} from "../../../../../http/requests/booking/sales";
import {addComma} from "../../../../../assets/js/functions";

export default {
  name: "showRefundRules",
  props: {
    invoiceId: {
      type: Number,
      default: () => { return 0 }
    },
    cancelType: {
      type: Number,
      default: () => { return 0 }
    }
  },
  data () {
    return {
      timeToReserve: 0,
      totalRefundPrice: 0,
      refundTime: {},
      refundAddon: {},
      refundCoach: {}
    }
  },
  created () {
    this.calculateCancelPrice()
  },
  methods: {
    calculateCancelPrice () {
      const payload = {
        cancel_type: this.cancelType
      }
      calculateCancelSaleInvoicePrice(this.invoiceId, payload).then(response => {
        const cancelPrice = response.data

        this.totalRefundPrice = cancelPrice.total_refund_amount

        if (cancelPrice.hasOwnProperty('time')) {
          this.timeToReserve = cancelPrice.time.refund_hour
          this.refundTime = {
            percent: cancelPrice.time.refund_percent || 0,
            price: cancelPrice.time.refund_amount
          }
        }

        if (cancelPrice.hasOwnProperty('addons')) {
          this.refundAddon = {
            percent: cancelPrice.addons.refund_percent || 0,
            price: cancelPrice.addons.refund_amount
          }
        }

        if (cancelPrice.hasOwnProperty('coach')) {
          this.refundCoach = {
            percent: cancelPrice.coach.refund_percent || 0,
            price: cancelPrice.coach.refund_amount
          }
        }
      })
    },

    addComma (value) {
      return addComma(value)
    }
  }
}
</script>

<style lang="scss" scoped>
  .invoice-refund-rules {
    margin-bottom: 15px;

    .refund-header {
      text-align: center;
      line-height: 35px;
    }

    .refund-rules-list {
      border: 1px solid #cecece;
      border-radius: 0.5rem;
      overflow: hidden;

      .refund-rules-item {
        display: flex;
        line-height: 35px;
        border-bottom: 1px solid #cecece;

        &:last-child {
          border-bottom: 0;
        }

        .refund-label {
          width: 30%;
          padding: 0 5px;
          border-right: 1px solid #cecece;
          background-color: #f8f8f8;
        }

        .refund-content {
          width: 70%;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          padding: 0 5px;

          .refund-percent {
            height: 100%;
            width: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-right: 1px solid #cecece;
          }

          .refund-price {
            flex-grow: 1;
            text-align: right;
          }
        }
      }
    }
  }
</style>
